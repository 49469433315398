<template>
  <div>
    <div class="ps-2">
      <HeaderPage />
    </div>
    <SeeMorePage
      :title="'購入店舗'"
      :data_list="orderHistoryStore"
      :data_type="'store'"
    />
    <v-pagination
      v-if="!isLengthHistoryStore"
      color="#ff0090"
      v-model="pageOrder"
      :total-visible="5"
      :length="orderHistoryStoreHeader.last_page"
      @input="nextPageOrderHistoryStore"
      style="margin-top: 10px"
    ></v-pagination>
    <Cashier />
    <Sidebar />
  </div>
</template>
<script>
import SeeMorePage from "../components/SeeMorePage.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";

export default {
  name: "ViewAllOrderByStore",
  components: {
    SeeMorePage,
    HeaderPage,
    Sidebar,
    Cashier,
  },
  data() {
    return {
      isLoadingFavStore: true,
      pageOrder: 1,
    };
  },
  methods: {
    async dispatchOrderHistoryStore() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingHStore = true;
        let res = await this.$store.dispatch(
          "order_module/orderHistoryByStore",
          {
            page: this.pageOrder,
          }
        );
        this.isLoadingHStore = false;
        return res;
      }
    },
    nextPageOrderHistoryStore() {
      this.$store.dispatch("order_module/orderHistoryByStore", {
        page: this.pageOrder,
      });
    },
  },
  computed: {
    orderHistoryStore() {
      return this.$store.state.order_module.orderHistoryStore;
    },
    isLengthHistoryStore() {
      return this.$store.state.order_module.is_length;
    },
    orderHistoryStoreHeader() {
      return this.$store.state.order_module.orderHistoryStoreHeader;
    },
  },
  created() {
    this.dispatchOrderHistoryStore();
  },
};
</script>
